import Container_AppleAreaComponent from '@wix/thunderbolt-elements/src/components/Container/viewer/skinComps/AppleArea/AppleArea.skin';


const Container_AppleArea = {
  component: Container_AppleAreaComponent
};


export const components = {
  ['Container_AppleArea']: Container_AppleArea
};


// temporary export
export const version = "1.0.0"
